import React, { useState } from "react"
import VisibilitySensor from 'react-visibility-sensor'
import { Box, Center, Flex, Text, Spinner } from '@chakra-ui/react'
import Loadable from 'react-loadable'
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';

export const SailorzBannerLoader = () => {


    const [visible, setVisible] = useState(false);

    const blockBecomeVisible = (isVisible) => {
        console.log('blockBecomeVisible');
        if (isVisible) {
            setVisible(true)
        } else {
            // setVisible(false)
        }
    }

    const SailorzBannerDesktop = (visible) ? Loadable({
        loader: () => import('./BannerDesktop'),
        loading() {
            return <Flex
                mx={{ base: 0, md: "2rem", lg: "2rem", xxl: "10rem" }}
                justify="center"
                border="dashed 2px"
                borderRadius="lg"
                borderColor="gray.100"
                background="gray.50"
                p="1rem"
                my="1rem"
                key={0}><Spinner color="blue.500" size="sm" />
            </Flex>
        }
    }) : null

    const SailorzBannerMobile = (visible) ? Loadable({
        loader: () => import('./BannerMobile'),
        loading() {
            return <Flex
                mx={{ base: 0, md: "2rem", lg: "2rem", xxl: "10rem" }}
                justify="center"
                border="dashed 2px"
                borderRadius="lg"
                borderColor="gray.100"
                background="gray.50"
                p="1rem"
                my="1rem"
                key={0}><Spinner color="blue.500" size="sm" />
            </Flex>
        }
    }) : null

    return null

    return (

        <VisibilitySensor
            onChange={blockBecomeVisible}
        // onChange={console.log('ad became visible')}
        >
            <>
                <BrowserView>

                    {SailorzBannerDesktop && isBrowser ?
                        <SailorzBannerDesktop />
                        :
                        <Center id='sailorz-banner'><Spinner /></Center>
                    }
                </BrowserView>
                <MobileView>

                    {SailorzBannerMobile && isMobile ?
                        // <SailorzBannerDesktop />
                        <SailorzBannerMobile />
                        :
                        <Center id='sailorz-banner'><Spinner /></Center>
                    }
                </MobileView>
            </>
        </VisibilitySensor>
    )
}